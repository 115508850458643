import CloseIcon from "@mui/icons-material/Close";
import * as Material from "@mui/material";
import axios, { AxiosError, AxiosResponse } from "axios";
import dayjs from "dayjs";
import jwt_decode, { JwtPayload } from "jwt-decode";
import _ from "lodash";
import qs from "query-string";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RequestManager } from "../RequestManager";
import * as ApiModelTypes from "../api/apiModelTypes";
import * as AuthenticationService from "../api/authenticationService";
import * as UserManagementService from "../api/userManagementService";
import {
	AXIOS_CANCEL_CODE,
	IS_AREA_51_GROUP,
	IS_DEV_ENVIRONMENT,
	IS_LOCAL_DEV_ENVIRONMENT
} from "../constants";
import { LandingPage } from "../scenes/LandingPage";
import { darkTheme, standardTheme } from "../themes";
import { Configure, recordEvent, updateEndpoint } from "../utils/analyticsUtil";
import { IsA51UserContext } from "../utils/contextUtils/isA51UserContext";
import {
	Timezone,
	TimezonePreferenceContext
} from "../utils/contextUtils/timezonePreferenceContext";
import {
	ACCESS_TOKEN,
	REFRESH_TOKEN,
	clearCookie,
	getCookie,
	setAuthCookie,
	setRefreshCookie
} from "../utils/cookieUtil";
import { DialogFactory, DialogInstance } from "../utils/dialogUtils";
import { sendMessageWebhook } from "../utils/discordUtils";
import {
	PINNED_POLICIES,
	PINNED_VINS,
	RECENT_VINS,
	REGION,
	SHOW_INTRO_MESSAGE,
	THEME_PREFERENCE,
	URL_HISTORY,
	USER_EVENT_REC_DATE,
	WAITING_FOR_ACCESS
} from "../utils/localStorage";
import { REGION_BASE_URL_MAP, Regions } from "../utils/regionUtils";
import { Roles } from "../utils/roleUtils";
import { WEB_GL_STATUS } from "../utils/sessionStorage";
import { FavoritesContext } from "../utils/userProfileUtils/favoritesContext";
import {
	UserFavorites,
	getFavoritesFromLocalStorage
} from "../utils/userProfileUtils/favoritesUtils";
import { UserPoliciesResponse, loadUserPolicies } from "../utils/userProfileUtils/userPolicies";
import "./App.scss";
import { EasterEgg } from "./EasterEgg";
import { withErrorBoundary } from "./ErrorBoundary/withErrorBoundary";
import { ErrorDialog } from "./ErrorDialog";
import { LeftNavigation } from "./LeftNav";
import { LoadingLogo } from "./LoadingLogo";
import { Notification } from "./Notification";
import { RoutedBody } from "./RoutedBody";
import { UserPermissionsContext } from "./UserPermissions";

// Integrating Web Analytics in DEV Environment
if (IS_LOCAL_DEV_ENVIRONMENT || IS_DEV_ENVIRONMENT) {
	Configure();
	updateEndpoint();
}

/**
 * The notification event.
 */
export interface NotificationEvent {
	/**
	 * The supported snackbar variants.
	 */
	variant: "success" | "info" | "warning" | "error";
	/**
	 * The snackbar message.
	 */
	message: string;
	/**
	 * A custom action to provide to the user as a button on the snackbar.
	 * This will ADD a button if used in tandem with a "link".
	 */
	customAction?: React.ReactNode;
	/**
	 * Optional link that users can navigate to from the snackbar.
	 */
	link?: string;
	/**
	 * Optional style that applies white-space: pre-line, used by vin feature request to display long error message
	 */
	isMultiline?: boolean;
}

/**
 * The settings required to enable a distinct notification.
 */
export interface NotificationSettings extends NotificationEvent {
	/**
	 * The snackbar key.
	 */
	key: number;
}

/**
 * Name of this component.
 */
const COMPONENT_NAME = "App";

/**
 * Core implementation of {@link App}
 * @return The rendered component.
 */
const AppCore: React.FC = () => {
	const localStorage = window.localStorage;
	let userLoadtimer: number | undefined;

	const navigate = useNavigate();
	const [me, setMe] = React.useState<ApiModelTypes.Me | undefined>(undefined);
	const [easterEgg, setEasterEgg] = React.useState<boolean>(false);
	const [easterEggCounter, setEasterEggCounter] = React.useState<number>(0);
	const [authenticated, setAuthenticated] = React.useState<boolean>(false);
	const [notificationSettings, setNotificationSettings] = React.useState<
		NotificationSettings | undefined
	>(undefined);
	const [isNotificationOpen, setIsNotificationOpen] = React.useState<boolean>(false);
	const [requestManagerError, setRequestManagerError] = React.useState<string | undefined>(
		undefined
	);
	const [region, setRegion] = React.useState<Regions>(
		localStorage.getItem(REGION) as Regions
	);
	const [themePreference, setThemePreference] = React.useState<string | null>(
		localStorage.getItem(THEME_PREFERENCE)
	);

	// Set default time zone to local
	const [timezonePreference, setTimezonePreference] = React.useState<Timezone | null>(
		localStorage.getItem("TIMEZONE_PREFERENCE") === "utc" ? "utc" : "local"
	);

	const [dialogInstance, setDialogInstance] = React.useState<DialogInstance | undefined>(
		undefined
	);

	// TODO: resolve lint warning via implementing prompt
	const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState<boolean>(false);

	const [isRequestingChangeOfAccess, setIsRequestingChangeOfAccess] =
		React.useState<boolean>(false);

	// The favorites from local storage
	const [userFavorites, setUserFavorites] = React.useState<UserFavorites>({
		recentVins: [],
		pinnedVins: [],
		pinnedPolicies: []
	});

	// User's policy details from user profile service
	const [userPolicies, setUserPolicies] = React.useState<UserPoliciesResponse>({
		createdPolicies: [],
		searchedPolicies: [],
		patchedPolicies: [],
		recentPolicies: [],
		isLoading: true
	});

	// Using ref to prevent re-render on refresh but still be able to access
	const accessTokenRef = React.useRef<string | null>(getCookie(ACCESS_TOKEN) || null);
	const refreshTokenRef = React.useRef<string | null>(getCookie(REFRESH_TOKEN) || null);

	const location = useLocation();

	/**
	 * Functions wrapped in useCallback. Prevents re-rendering in children components on invocation.
	 * Most effective when used on functions passed as props to deeply nested children components.
	 */
	const onPermissionChangeRequest = React.useCallback(() => {
		setIsRequestingChangeOfAccess(true);
	}, []);

	const onEasterEgg = React.useCallback(() => {
		setEasterEggCounter((easterEggCounter) => easterEggCounter + 1);
	}, []);

	const onDirtyChanged = React.useCallback(
		(isDirty: boolean | ((prevState: boolean) => boolean)) => setHasUnsavedChanges(isDirty),
		[]
	);
	const onHideIntroMessage = React.useCallback(() => {
		localStorage.setItem(SHOW_INTRO_MESSAGE, "no");
		setNotificationSettings(undefined);
	}, []);

	if (!localStorage.getItem(SHOW_INTRO_MESSAGE)) {
		localStorage.setItem(SHOW_INTRO_MESSAGE, "yes");
	}

	// TODO: What should the default region be?
	if (!localStorage.getItem(REGION)) {
		localStorage.setItem(REGION, Regions.NA);
		setRegion(Regions.NA);
	}

	// Check for theme preference. Default to light theme if not set.
	if (!localStorage.getItem(THEME_PREFERENCE)) {
		localStorage.setItem(THEME_PREFERENCE, "LIGHT");
		setThemePreference("LIGHT");
	}

	/**
	 * Constant paths that cannot be retried with a refresh token
	 */
	const NO_RETRY_PATHS = {
		// Will cause infinite loop
		token: "auth/token",
		// Does not authenticate with token
		getMe: "user/me"
	};

	/**
	 * Fetches new policies on region change
	 * Since policies are passed through context to multiple parts of the app
	 * we can't just use a key to refresh them
	 */
	React.useEffect(() => {
		if (authenticated) {
			fetchUserPolicies();
		}
	}, [region]);

	/**
	 * Decodes and checks token expiration time against current time
	 * @returns true if token is not expired
	 */
	const isTokenValid = (token = accessTokenRef.current): boolean => {
		// Wrapping in try to safeguard attempting to decode null or undefined token
		// SHOULD NOT be null or undefined, if not in local storage, we force login to fetch new token
		try {
			const decodedToken: JwtPayload = jwt_decode(token!);
			const expirationTime: number | undefined = decodedToken.exp;
			const currentTime = dayjs().unix();
			if (!expirationTime) {
				throw new Error();
			}
			// Token is valid if expiration time greater than current time
			return expirationTime > currentTime;
		} catch (error) {
			// If the token is null, undefined, or not a valid format and threw an error, it's assumed not valid
			return false;
		}
	};

	/**
	 * Called to refresh a bearer token
	 */
	const refreshAuthToken = async (): Promise<void> => {
		if (refreshTokenRef.current) {
			return new Promise(async (resolve, reject) => {
				await requestManager
					.send(UserManagementService.refreshAuthToken, {
						payload: { refreshToken: refreshTokenRef.current }
					})
					.then((response: AxiosResponse<ApiModelTypes.RefreshTokenResponse>) => {
						if (!response.data.access_token) {
							// The interceptor handling the error will either logout the user or inform the user
							throw new Error();
						}
						// Set new access token ref, in local storage, and request manager
						accessTokenRef.current = response.data.access_token;
						setAuthCookie(ACCESS_TOKEN, accessTokenRef.current!, "/");
						requestManager!.setAccessToken(accessTokenRef.current!);
						resolve();
					})
					.catch(() => {
						// The interceptor handling the error will either logout the user or inform the user
						reject();
					});
			});
		} else {
			// The interceptor handling the error will either logout the user or inform the user
			throw new Error();
		}
	};
	if (!localStorage.getItem("TIMEZONE_PREFERENCE")) {
		localStorage.setItem("TIMEZONE_PREFERENCE", "local");
	}

	const requestManager: RequestManager = React.useMemo(() => {
		return new RequestManager(
			{
				handleError: (errorMessage) => {
					setRequestManagerError(errorMessage);
				},
				handleForbiddenError: (url) => {
					setRequestManagerError(
						`You are not allowed to access the resource located at ${url}.`
					);
				},
				handleUnauthorizedError: () => {
					onLogout();
				},
				handleInternalServerError: (errorName, errorMessage) => {
					if (errorName === "TokenExpiredError") {
						onLogout();
					}

					setRequestManagerError(
						`The server has encountered the following issue: ${errorMessage}.`
					);
				},
				handleBadRequestError: (errorMessage) => {
					setRequestManagerError(
						`Your request has the following issue(s): ${errorMessage}.`
					);
				},
				handleNotFoundError: (errorMessage) => {
					setRequestManagerError(`The resource could not be found: ${errorMessage}.`);
				}
			},
			accessTokenRef.current,
			region,
			region ? REGION_BASE_URL_MAP[region as Regions] : undefined
			
		);
	}, [accessTokenRef.current, region]);

	React.useEffect(() => {
		// Check for webgl and display
		const webGl = document.createElement("canvas").getContext("webgl");
		handleWebGl(webGl);

		const isAccessTokenValid = isTokenValid(accessTokenRef.current);

		/**
		 * The promise if any referencing the in progress refresh token request
		 */
		let refreshTokenPromise: Promise<void> | null = null;
		if (isAccessTokenValid) {
			setAuthenticated(true);
		} else {
			const values = qs.parse(location.search);

			if (!values || !values.code) {
				onLogout();
			}

			if (!_.isString(values.code)) {
				return;
			}

			requestManager
				.send(AuthenticationService.postAuthToken, {
					queryParameters: { code: values.code }
				})
				.then((result) => {
					const payload = result.data;
					if (!payload || result.status === 500) {
						window.location = process.env.REACT_APP_REDIRECT as any;
					} else {
						setAuthCookie(ACCESS_TOKEN, payload.access_token!, "/");
						setRefreshCookie(REFRESH_TOKEN, payload.refresh_token!, "/");
						accessTokenRef.current = payload.access_token!;
						refreshTokenRef.current = payload.refresh_token!;
						requestManager!.setAccessToken(payload.access_token!);
						setAuthenticated(true);

						const urlHistory = localStorage.getItem(URL_HISTORY);
						if (urlHistory) {
							navigate(urlHistory);
						}
					}
				})
				.catch(() => {
					setAuthenticated(false);
					window.location = process.env.REACT_APP_REDIRECT as any;
				});
		}

		DialogFactory.setFactoryFunction(setDialogInstance);

		// Numerical id to eject in the cleanup callback
		// NOTE: This code block is executed at each API response via the request
		// manager, prior to their first ".then()", if applicable
		const interceptorId = axios.interceptors.response.use(
			/**
			 * Success handler - return response to caller.
			 * @param res - the success response.
			 * @returns - the response.
			 */
			(res) => {
				return res;
			},
			/**
			 * Error handler.
			 * Attempts to refresh token and retry request.
			 * @param error - the error object.
			 * @returns - The response or the error if not able to refresh.
			 */
			async (error): Promise<AxiosResponse | AxiosError | void> => {
				if (axios.isCancel(error) || error.code === AXIOS_CANCEL_CODE) {
					// Return response for request manager to handle in then block
					return error;
				}
				// The token used in the request
				const requestToken = error.config.headers.Authorization.split(" ")[1];
				if (isTokenValid(requestToken)) {
					return Promise.reject(error);
				} else if (
					// there isn't a current access token value - can't retry
					!accessTokenRef.current ||
					// OR there isn't a refresh token value - can't refresh
					!refreshTokenRef.current ||
					// If it was a token url (refresh or auth) attempt, don't retry - return
					error.config.url.includes(NO_RETRY_PATHS.token) ||
					// If request was already retried (error.config._retry), return
					error.config._retry
				) {
					throw new Error(
						"Your session is expired and your request failed. Copy unsaved work, logout, and log back in"
					);
				} else {
					// Refresh access token
					// Always try again and set _retry flag on request to prevent an infinite loop
					if (!refreshTokenPromise) {
						// Refresh token and flip refresh boolean
						refreshTokenPromise = refreshAuthToken().catch(() => {
							// If the refresh failed and the request was GET user/me, just logout
							if (error.config.url.includes(NO_RETRY_PATHS.getMe)) {
								onLogout();
							} else {
								// Throw error so request manager can handle it
								throw new Error(
									"Your session is expired and your request failed. Copy unsaved work, logout, and log back in"
								);
							}
						});
					}
					return await refreshTokenPromise
						.then(async () => {
							// Retry request with new token and return result
							error.config._retry = true;
							error.config.headers.Authorization = `Bearer ${accessTokenRef.current!}`;
							const refreshResult = await axios(error.config);
							// If success, will return.
							// If error, will go through error handler but return the error due to _refresh flag
							return refreshResult;
						})
						.catch((retryError) => {
							console.log("Error retrying request", retryError);
							return Promise.reject(retryError);
						});
				}
			}
		);
		// Cleanup. Remove interceptor
		return () => {
			axios.interceptors.response.eject(interceptorId);
		};
	}, []);

	React.useEffect(() => {
		if (!authenticated) {
			return;
		}
		loadUser();
		fetchUserPolicies();
		// Gets Favorites from local storage and sets them in state
		setUserFavorites({
			...userFavorites,
			recentVins: getFavoritesFromLocalStorage(RECENT_VINS),
			pinnedVins: getFavoritesFromLocalStorage(PINNED_VINS),
			pinnedPolicies: getFavoritesFromLocalStorage(PINNED_POLICIES)
		});
	}, [authenticated]);

	/**
	 * Every time the app route is changed reset the flag for unsaved changes.
	 * TODO: Reimplement Prompt when available
	 */
	React.useEffect(() => {
		setHasUnsavedChanges(false);

		// NOTE: Without this conditional, the history may be set to the root of the application
		// while the login process is happening.
		if (authenticated) {
			localStorage.setItem(URL_HISTORY, location.pathname);
		}
	}, [location.pathname]);

	/* * Listens to user's preference and toggles the theme
	 */
	window.addEventListener("changeTheme", () => {
		onToggleTheme();
	});

	/**
	 * Loads the user and the user's groups silently and will wait
	 * until there is a change in the user's operations.
	 */
	const loadUserSilently = async () => {
		const theNewMe = (await requestManager!.send(UserManagementService.getMe))?.data;

		if (!theNewMe) {
			setAuthenticated(false);
			return;
		}

		if (!_.isEqual(me, theNewMe) && userLoadtimer) {
			clearInterval(userLoadtimer);
			localStorage.removeItem(WAITING_FOR_ACCESS);
			window.location = process.env.REACT_APP_REDIRECT as any;
		}
	};

	/**
	 * Loads the user and the user's groups.
	 */
	const loadUser = async () => {
		try {
			const me = (await requestManager!.send(UserManagementService.getMe))?.data;

			if (!me) {
				setAuthenticated(false);
				return;
			}

			setMe(me);

			// Checks if the event has already been recorded today
			const userEventRecDate = localStorage.getItem(USER_EVENT_REC_DATE);
			const today = new Date().toLocaleDateString();

			if (userEventRecDate !== today) {
				// Records the event only if it hasn't been recorded today
				recordEvent("Users ", `${me.given_name} ${me.family_name}`);
				// Update the last event date in local storage
				localStorage.setItem(USER_EVENT_REC_DATE, today);
			}
		} catch (error) {
			onLogout();
		}
	};

	/**
	 * Loads user's policies from user api
	 */
	const fetchUserPolicies = () => {
		// Sets loading to true for user profile loading state
		setUserPolicies({ ...userPolicies, isLoading: true });
		// Gets user policies from user service
		loadUserPolicies(requestManager).then((result) =>
			setUserPolicies({
				...userPolicies,
				createdPolicies: [...new Set(result.createdPolicies)],
				searchedPolicies: [...new Set(result.searchedPolicies)],
				patchedPolicies: [...new Set(result.patchedPolicies)],
				recentPolicies: [...new Set(result.recentPolicies)],
				/**
				 * Used for loading state in components consuming this
				 * They will be waiting for this flag to turn false to switch from loading state
				 */
				isLoading: false
			})
		);
	};

	/**
	 * Event fired when a notification is to be displayed.
	 * @param notificationEvent - the notification event.
	 */
	const handleDisplayNotification = (notificationEvent: NotificationEvent) => {
		if (isNotificationOpen) {
			// immediately begin dismissing current message
			// to start showing new one
			setIsNotificationOpen(false);
		}
		setNotificationSettings({
			...notificationEvent,
			key: new Date().getTime()
		});
		setIsNotificationOpen(true);
	};

	/**
	 * Displays a toast message if no webgl is detected
	 * @param webGlContext - webgl instance
	 */
	const handleWebGl = (webGlContext: WebGLRenderingContext | null): void => {
		if (!webGlContext) {
			sessionStorage.setItem(WEB_GL_STATUS, "DISABLED");
			const messageToDisplay =
				"Your browser does not support webgl or it is disabled. Please make sure it is enabled to receive the best user experience.";
			handleDisplayNotification({
				message: `${messageToDisplay}`,
				variant: "warning",
				customAction: (
					<Material.IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={() => {
							setNotificationSettings(undefined);
						}}
					>
						<CloseIcon fontSize="small" />
					</Material.IconButton>
				)
			});
		} else {
			sessionStorage.setItem(WEB_GL_STATUS, "ENABLED");
		}
	};

	/**
	 * Called when the user wishes to logout.
	 */
	const onLogout = () => {
		localStorage.setItem(URL_HISTORY, location.pathname);
		clearCookie();
		setAuthenticated(false);
		window.location = process.env.REACT_APP_REDIRECT as any;
	};

	/**
	 * Called when the user wishes to view his account.
	 */
	const onViewUserAccount = () => {
		navigate("/user-profile");
	};

	React.useEffect(() => {
		if (easterEggCounter % 5 === 0 && easterEggCounter !== 0) {
			setEasterEgg(true);
		} else {
			setEasterEgg(false);
		}
	}, [easterEggCounter]);

	/**
	 * Called when the user toggles the theme color mode
	 */
	const onToggleTheme = () => {
		let theme = localStorage.getItem(THEME_PREFERENCE);
		if (theme === "LIGHT") {
			recordEvent("ToggleTheme", "Dark");
			localStorage.setItem(THEME_PREFERENCE, "DARK");
			theme = "DARK";
		} else {
			recordEvent("ToggleTheme", "Light");
			localStorage.setItem(THEME_PREFERENCE, "LIGHT");
			theme = "LIGHT";
		}
		setThemePreference(theme);
	};

	/**
	 * Called when user toggles the time zone preference. Sets new value in local storage and state variable
	 */
	const onToggleTimezone = () => {
		const timezone = localStorage.getItem("TIMEZONE_PREFERENCE");
		if (timezone === "local") {
			localStorage.setItem("TIMEZONE_PREFERENCE", "utc");
			setTimezonePreference("utc");
		} else {
			localStorage.setItem("TIMEZONE_PREFERENCE", "local");
			setTimezonePreference("local");
		}
	};

	if (!authenticated || !me) {
		return <LoadingLogo />;
	}

	/**
	 * Renders a snackbar notification
	 * @returns a rendered notification
	 */
	const renderNotification = () => {
		if (notificationSettings) {
			return (
				<Notification
					variant={notificationSettings.variant}
					isOpen={isNotificationOpen}
					snackbarKey={notificationSettings.key}
					message={notificationSettings.message}
					isMultiline={notificationSettings.isMultiline}
					onClose={() => {
						setIsNotificationOpen(false);
					}}
					actionButton={
						<React.Fragment>
							{notificationSettings.link && (
								<Material.Button
									key="view"
									color="inherit"
									size="small"
									onClick={() => {
										const notificationLink = notificationSettings.link!;
										navigate(notificationLink);
										setIsNotificationOpen(false);
									}}
								>
									Go
								</Material.Button>
							)}
							{notificationSettings.customAction}
						</React.Fragment>
					}
				/>
			);
		}
	};

	if (themePreference === "DARK") {
		document.body.className = "dark-theme";
	} else {
		document.body.classList.remove("dark-theme");
	}

	if (
		// The user is assumed to be a new user if the only
		// permission they have is to view the website.
		_.isEqual(
			_.sortBy(me.operations),
			_.sortBy([
				ApiModelTypes.UserPermissions.ADA_API_WEB_ACCESS_NA,
				ApiModelTypes.UserPermissions.ADA_API_WEB_ACCESS_EU
			])
		) ||
		isRequestingChangeOfAccess
	) {
		return (
			<>
				<LandingPage
					waitingForAccess={localStorage.getItem(WAITING_FOR_ACCESS)}
					onRequestAccess={(
						userGroup: string,
						selectedRoles: Roles[],
						selectedRegions: Regions[],
						businessJustification: string
					) => {
						const roles = _.join(selectedRoles, ", ");
						const regions = _.join(selectedRegions, ", ");
						return new Promise((resolve, reject) => {
							sendMessageWebhook(
								`${me.given_name} ${me.family_name} is Requesting ${
									isRequestingChangeOfAccess
										? "a Change of Permissions"
										: "Access"
								}, Click here to grant`,
								[
									{
										label: "Environment",
										value: process.env.REACT_APP_ENVIRONMENT || "N/A"
									},
									{
										label: "Regions",
										value: regions
									},
									{
										label: "Roles",
										value: roles
									},
									{
										label: "Group Identity",
										value: userGroup
									},
									{
										label: "Business Justification",
										value: businessJustification
									}
								],
								`${window.location.origin}/users/${me.cognito_username}`
							)
								.then((response) => {
									localStorage.setItem(WAITING_FOR_ACCESS, "true");
									handleDisplayNotification({
										variant: "success",
										message:
											"ADA Web administrators have received your request to update your ADA Web permissions. We appreciate your patience while your request is being approved. Click anywhere to continue."
									});
									resolve(response);
								})
								.catch((error) => {
									if (
										localStorage.getItem(WAITING_FOR_ACCESS) &&
										!isRequestingChangeOfAccess
									) {
										localStorage.removeItem(WAITING_FOR_ACCESS);
									}
									handleDisplayNotification({
										variant: "error",
										message:
											"There was an error sending your request. Please ensure all privacy and ad-blocking extensions such as Privacy Badger are turned off for this site, refresh your browser, then try again."
									});
									reject(error);
								});
						});
					}}
					onProdRequestAccess={(
						userGroup: string,
						selectedRegions: Regions[],
						businessJustification: string,
						isReqArea51: boolean
					) => {
						const regions = _.join(selectedRegions, ", ");
						const message = isReqArea51
							? `${me.given_name} ${me.family_name} is Requesting Access to Area51, Click here to grant`
							: `${me.given_name} ${me.family_name} is Requesting Access, Click here to grant`;
						/**
						 * 	 Area51 requests differs from normal requests in that it does not require
						 * 	 requested Region(s) or Group Identity information
						 */
						const details = isReqArea51
							? [
									{
										label: "Business Justification",
										value: businessJustification
									}
							  ]
							: [
									{
										label: "Environment",
										value: process.env.REACT_APP_ENVIRONMENT || "N/A"
									},
									{
										label: "Regions",
										value: regions
									},
									{
										label: "Group Identity",
										value: userGroup
									},
									{
										label: "Business Justification",
										value: businessJustification
									}
							  ];
						return new Promise((resolve, reject) => {
							sendMessageWebhook(
								message,
								details,
								`${window.location.origin}/users/${me.cognito_username}`
							)
								.then((res) => {
									localStorage.setItem(WAITING_FOR_ACCESS, "true");
									resolve(res);
								})
								.catch((error) => {
									if (
										localStorage.getItem(WAITING_FOR_ACCESS) &&
										!isRequestingChangeOfAccess
									) {
										localStorage.removeItem(WAITING_FOR_ACCESS);
									}
									handleDisplayNotification({
										variant: "error",
										message:
											"There was an error sending your request. Please ensure all privacy and ad-blocking extensions such as Privacy Badger are turned off for this site, refresh your browser, then try again."
									});
									reject(error);
								});
						});
					}}
					onWaitForAccess={() => {
						userLoadtimer = window.setInterval(loadUserSilently, 5000);
					}}
					user={me}
					isRequestingChange={isRequestingChangeOfAccess}
				/>
				{notificationSettings && renderNotification()}
			</>
		);
	}

	return (
		<Material.ThemeProvider theme={themePreference === "DARK" ? darkTheme : standardTheme}>
			<Material.CssBaseline />
			<Material.Fade>
				<UserPermissionsContext.Provider value={me.operations!}>
					<div className={COMPONENT_NAME}>
						<main className={`${COMPONENT_NAME}__main-view`}>
							<IsA51UserContext.Provider
								value={
									(me.groups &&
										me.groups.some((group) => IS_AREA_51_GROUP(group))) ||
									false
								}
							>
								<FavoritesContext.Provider
									value={{
										...userPolicies,
										...userFavorites,
										setUserFavorites,
										fetchUserPolicies
									}}
								>
									<LeftNavigation
										user={me}
										onLogout={onLogout}
										onPermissionChangeRequest={onPermissionChangeRequest}
										onEasterEgg={onEasterEgg}
										regionConfig={{
											region: region || "",
											onChangeRegion: (region) => {
												localStorage.setItem(REGION, region as Regions);
												setRegion(region as Regions);
												recordEvent("Region Change", `${region}`);
											}
										}}
										onToggleTheme={onToggleTheme}
										isDarkMode={themePreference === "DARK" ? true : false}
										onViewUserAccount={() => {
											onViewUserAccount();
										}}
										onToggleTimezone={onToggleTimezone}
										timezonePreference={timezonePreference}
									/>
									{/* NOTE: Consider wrapping <RoutedBody/> in React.createContext here
							to avoid prop-drilling through the component tree down to <Support/> */}
									<TimezonePreferenceContext.Provider value={timezonePreference}>
										<RoutedBody
											isDarkMode={themePreference === "DARK" ? true : false}
											user={me}
											requestManager={requestManager!}
											onDisplayNotification={handleDisplayNotification}
											onDirtyChanged={onDirtyChanged}
											onHideIntroMessage={onHideIntroMessage}
											region={region as Regions}
											isRequestingChange={isRequestingChangeOfAccess}
											onWaitForAccess={() => {
												userLoadtimer = window.setInterval(
													loadUserSilently,
													5000
												);
											}}
											onRequestAccess={(
												userGroup: string,
												selectedRoles: Roles[],
												selectedRegions: Regions[],
												businessJustification: string
											) => {
												const roles = _.join(selectedRoles, ", ");
												const regions = _.join(selectedRegions, ", ");
												return new Promise((resolve, reject) => {
													sendMessageWebhook(
														`${me.given_name} ${me.family_name} is Requesting a Change of Permissions, Click here to grant`,
														[
															{
																label: "Environment",
																value:
																	process.env
																		.REACT_APP_ENVIRONMENT ||
																	"N/A"
															},
															{
																label: "Regions",
																value: regions
															},
															{
																label: "Roles",
																value: roles
															},
															{
																label: "Group Identity",
																value: userGroup
															},
															{
																label: "Business Justification",
																value: businessJustification
															}
														],
														`${window.location.origin}/users/${me.cognito_username}`
													)
														.then((response) => {
															localStorage.setItem(
																WAITING_FOR_ACCESS,
																"true"
															);
															handleDisplayNotification({
																variant: "success",
																message:
																	"ADA Web administrators have received your request to update your ADA Web permissions. We appreciate your patience while your request is being approved. Click anywhere to continue."
															});
															resolve(response);
														})
														.catch((error) => {
															handleDisplayNotification({
																variant: "error",
																message:
																	"There was an error sending your request. Please ensure all privacy and ad-blocking extensions such as Privacy Badger are turned off for this site, refresh your browser, then try again."
															});
															reject(error);
														});
												});
											}}
											// this is not actually used, it is here to satisfy the prop interface
											onProdRequestAccess={(
												userGroup: string,
												selectedRegions: Regions[],
												businessJustification: string
											) => {
												const regions = _.join(selectedRegions, ", ");
												return new Promise((resolve, reject) => {
													sendMessageWebhook(
														`${me.given_name} ${me.family_name} is Requesting Access, Click here to grant`,
														[
															{
																label: "Environment",
																value:
																	process.env
																		.REACT_APP_ENVIRONMENT ||
																	"N/A"
															},
															{
																label: "Regions",
																value: regions
															},
															{
																label: "Group Identity",
																value: userGroup
															},
															{
																label: "Business Justification",
																value: businessJustification
															}
														],
														`${window.location.origin}/users/${me.cognito_username}`
													)
														.then((res) => {
															localStorage.setItem(
																WAITING_FOR_ACCESS,
																"true"
															);
															resolve(res);
														})
														.catch((error) => {
															if (
																localStorage.getItem(
																	WAITING_FOR_ACCESS
																) &&
																!isRequestingChangeOfAccess
															) {
																localStorage.removeItem(
																	WAITING_FOR_ACCESS
																);
															}
															handleDisplayNotification({
																variant: "error",
																message:
																	"There was an error sending your request. Please ensure all privacy and ad-blocking extensions such as Privacy Badger are turned off for this site, refresh your browser, then try again."
															});
															reject(error);
														});
												});
											}}
										/>
									</TimezonePreferenceContext.Provider>
								</FavoritesContext.Provider>
							</IsA51UserContext.Provider>
						</main>
						<Material.Dialog
							// This is a top level dialog wrapper for alerts and confirmations that are generated
							// via {@link utils/dialogUtils #DialogFactory}
							open={!!dialogInstance}
							onClose={() => {
								setDialogInstance(undefined);
							}}
							aria-labelledby={`${dialogInstance?.id}-dialog-title`}
							aria-describedby={`${dialogInstance?.id}-dialog-description`}
						>
							{dialogInstance?.content}
						</Material.Dialog>
						<ErrorDialog
							isOpen={!!requestManagerError}
							message={requestManagerError}
							onClose={() => {
								setRequestManagerError(undefined);
							}}
						/>
						{notificationSettings && renderNotification()}
						{easterEgg && <EasterEgg />}
					</div>
				</UserPermissionsContext.Provider>
			</Material.Fade>
		</Material.ThemeProvider>
	);
};

/**
 * The ADA web application.
 */
export const App = withErrorBoundary(AppCore);
App.displayName = COMPONENT_NAME;
